exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-layouts-js": () => import("./../../../src/pages/layouts.js" /* webpackChunkName: "component---src-pages-layouts-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-case-study-case-study-template-jsx": () => import("./../../../src/templates/case-study/case-study.template.jsx" /* webpackChunkName: "component---src-templates-case-study-case-study-template-jsx" */),
  "component---src-templates-page-page-template-jsx": () => import("./../../../src/templates/page/page.template.jsx" /* webpackChunkName: "component---src-templates-page-page-template-jsx" */),
  "component---src-templates-post-post-template-jsx": () => import("./../../../src/templates/post/post.template.jsx" /* webpackChunkName: "component---src-templates-post-post-template-jsx" */),
  "component---src-templates-whitepaper-whitepaper-template-jsx": () => import("./../../../src/templates/whitepaper/whitepaper.template.jsx" /* webpackChunkName: "component---src-templates-whitepaper-whitepaper-template-jsx" */)
}

